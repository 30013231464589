import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { cloudinaryImageUrl } from '@scentregroup/ui/tools/cloudinary/image'
import style from './index.module.scss'

const getCloudinaryImageUrl = backgroundImage =>
  cloudinaryImageUrl({
    src: backgroundImage,
    numberofItems: 1,
    ratio: 'standard',
  })

const ContentGroup = ({
  children,
  backgroundImage,
  className,
  skipTopPadding = false,
}) => (
  <div
    className={classNames(className, style.Group, style.evolved, {
      [style.backgroundImage]: backgroundImage,
      [style.skipTopPadding]: skipTopPadding,
    })}
    style={
      backgroundImage
        ? {
            backgroundImage: `url(${getCloudinaryImageUrl(backgroundImage)})`,
          }
        : null
    }
  >
    {children}
  </div>
)

ContentGroup.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node.isRequired,
  skipTopPadding: PropTypes.bool,
  className: PropTypes.string,
}

export default ContentGroup
