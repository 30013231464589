import React from 'react'
import { theme, colors } from '@hub/design-system-base/src/theme'
import { H1, H2, H3, H5 } from '@hub/heading'
import { Text } from '@hub/text'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import { HubStyleObject } from '@hub/design-system-base'

type HeadingSize =
  | 'megasmalldivider'
  | 'extrasmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'extralarge'

type Colors = keyof typeof colors | 'none'

interface CardHeaderProps {
  title?: string
  heading?: string
  subHeading?: string
  headingSize?: HeadingSize
  headerElement?: React.ReactNode
  headerUnderline?: boolean
}
interface CardProps extends CardHeaderProps {
  cardColor?: Colors
  sx?: HubStyleObject
  className?: string
}

const headingElement: (
  heading: string,
  size: HeadingSize
) => JSX.Element | null = (heading, size) => {
  switch (size) {
    case 'megasmalldivider':
      return (
        <H5
          fontSize={['font-h5-mobile', 'font-h5-mobile', 'font-h5']}
          sx={{
            color: theme.colors.brand,
            textTransform: 'uppercase',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&:after': {
              content: '',
              borderTop: `1px solid ${theme.colors.ghost}`,
              margin: `0 0 0 ${theme.space['size-2']}`,
            },
          }}
        >
          {heading}
        </H5>
      )
    case 'extrasmall':
      return (
        <H5 fontSize={['font-h5-mobile', 'font-h5-mobile', 'font-h5']}>
          {heading}
        </H5>
      )
    case 'small':
      return (
        <H3 fontSize={['font-h3-mobile', 'font-h3-mobile', 'font-h3']}>
          {heading}
        </H3>
      )
    case 'medium':
      return (
        <H2 fontSize={['font-h2-mobile', 'font-h2-mobile', 'font-h1']}>
          {heading}
        </H2>
      )
    case 'large':
      return (
        <H1 fontSize={['font-h1-mobile', 'font-h1-mobile', 'font-5xl']}>
          {heading}
        </H1>
      )
    case 'extralarge':
      return (
        <H1 fontSize={['font-5xl', 'font-5xl', 'font-promo']}>{heading}</H1>
      )
    default:
      return null
  }
}

const CardHeader: React.FC<React.PropsWithChildren<CardHeaderProps>> = ({
  heading,
  subHeading,
  headingSize = 'large',
  headerElement,
  headerUnderline,
}) => {
  return (
    <Box
      sx={
        headerUnderline
          ? {
              borderBottom: `1px solid ${theme.colors.ghost}`,
              marginBottom: ['spacing-5'],
              paddingBottom: ['spacing-2', 'spacing-4', 'spaccing-4'],
            }
          : {}
      }
    >
      {heading && headingElement(heading, headingSize)}
      {subHeading && (
        <Box marginTop="spacing-2" marginBottom="spacing-1">
          <Text>{subHeading}</Text>
        </Box>
      )}
      {headerElement && <Box>{headerElement}</Box>}
    </Box>
  )
}

const Card: React.FunctionComponent<React.PropsWithChildren<CardProps>> = ({
  heading,
  subHeading,
  headingSize,
  children,
  headerElement,
  className,
  cardColor = 'white',
  headerUnderline = false,
  sx,
}) => (
  <Stack
    sx={{
      ...(cardColor !== 'none' && { backgroundColor: theme.colors[cardColor] }),
      ...sx,
    }}
    className={className}
  >
    <CardHeader
      heading={heading}
      subHeading={subHeading}
      headingSize={headingSize}
      headerElement={headerElement}
      headerUnderline={headerUnderline}
    />
    <Box marginTop="spacing-none">{children}</Box>
  </Stack>
)

export default Card
