import React from 'react'
import get from 'lodash/get'
import classNames from 'classnames'
import { Box } from '@hub/box'
import ContentGroup from '../content-group'
import { CustomThemeContext } from '../../context'
import Module from './module'
import style from './index.module.scss'

interface ModulesProps {
  modules: Record<string, unknown>[]
  className?: string
}

const Modules: React.FC<ModulesProps> = ({ className, modules }) => {
  return (
    <CustomThemeContext.Consumer>
      {() => (
        <Box
          className={classNames(className, style.moduleGroup, style.evolved)}
        >
          {modules.map((module, i) => {
            const moduleId = get(module, 'sys.id')

            return (
              <ContentGroup
                backgroundImage={false}
                key={`module-${moduleId}-${i}`}
              >
                <Module moduleProps={module} />
              </ContentGroup>
            )
          })}
        </Box>
      )}
    </CustomThemeContext.Consumer>
  )
}

export default Modules
