/* eslint jsx-a11y/no-redundant-roles: 0 */
import React, { Children, cloneElement, Component } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { uniqueId } from 'lodash'
import { H5 } from '@hub/heading'
import { Container } from '@hub/container'
import DeckScrollWrapper from '../deck-scroll-wrapper'
import analyticsUtils from '../../helpers/analytics-utils'
import style from './index.module.scss'

class FeaturedContent extends Component {
  trackClickEvent = item => {
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: analyticsUtils.events.PROMOTION_CLICK,
      ecommerce: {
        promoClick: {
          promotions: {
            id: item.id,
            name: item.title,
            position: analyticsUtils.positions.FEATURED_CONTENT,
            dimension90: analyticsUtils.dimensions.DIMENSION90,
            creative: item.body,
          },
        },
      },
    })
  }

  render() {
    const { children, title, padded = false } = this.props
    const id = uniqueId('featured-content-')
    return (
      <Container
        gutter="spacing-none"
        className={classNames(style.deckContainer, style.evolved)}
        sx={{
          marginX: 'auto',
        }}
      >
        {title && (
          <H5
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 'spacing-md',
              textTransform: 'none',
              '&:after': {
                content: '""',
                display: 'block',
                borderTop: '1px solid',
                borderColor: 'borderTertiary',
                marginLeft: 'spacing-sm',
                flexGrow: 1,
              },
            }}
            id={id}
          >
            {title}
          </H5>
        )}
        <DeckScrollWrapper
          contentClassName={style.featuredContentScroll}
          className={style.contentWrapper}
          useCosmeticScrollbar={false}
          eventCategory={analyticsUtils.categories.WHATS_HAPPENING_FEATURED}
          eventAction={analyticsUtils.actions.CLICK}
        >
          <ol className={style.deckCardList} aria-labelledby={id} role="list">
            {Children.map(children, child =>
              cloneElement(child, {
                className: style.deckCard,
                onClick: () => this.trackClickEvent(child.props),
                padded,
              })
            )}
          </ol>
        </DeckScrollWrapper>
      </Container>
    )
  }
}

FeaturedContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
}

export default FeaturedContent
