import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from '@hub/container'
import AutoPotentialLink from '../auto-potential-link'
import {
  CloudinaryImage,
  IMAGE_SIZES_FULL_WIDTH,
} from '../../hub-components/cloudinary-image'
import Trackable from '../trackable'
import { trackingActions, trackingTypes } from '../../constants'
import style from './index.module.scss'

const FlipFlop = ({
  children,
  imageUrl,
  position,
  link,
  className,
  analyticsMetadata,
  heading,
}) => {
  const aria = {}
  if (heading) {
    aria['aria-label'] = heading
  }

  return (
    <Trackable
      trackingAction={trackingActions.viewPromotion}
      type={trackingTypes.promotion}
      {...analyticsMetadata}
    >
      <Container
        gutter="spacing-none"
        className={classNames(style.FlipFlop, style[position], className)}
        sx={{
          display: ['block', null, 'flex'],
          marginX: 'auto',
          alignItems: 'center',
        }}
      >
        <AutoPotentialLink link={link} {...aria}>
          <CloudinaryImage
            imageSetOrImage={{
              url: imageUrl,
              type: 'WIDE',
            }}
            sizes={IMAGE_SIZES_FULL_WIDTH}
            loadingMode="eager"
          />
        </AutoPotentialLink>
        {children}
      </Container>
    </Trackable>
  )
}

FlipFlop.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  imageUrl: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.string,
  lazyLoad: PropTypes.bool,
  className: PropTypes.string,
  analyticsMetadata: PropTypes.shape({
    correlationId: PropTypes.string,
    title: PropTypes.string,
    promotion: PropTypes.shape({
      creative: PropTypes.string,
    }),
  }),
  heading: PropTypes.string,
}

export default FlipFlop
