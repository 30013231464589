import React from 'react'
import has from 'lodash/has'
import isString from 'lodash/isString'
import { Container } from '@hub/container'
import { Box } from '@hub/box'
import { DeckCard } from '../../deck-v2'
import FeaturedContent from '../../featured-content'
import Promotion from '../../promotion'
import ComparisonItem from '../../comparison-item'
import { PromotionPositionContext } from '../../../context'
import { Promotion as IPromotion } from '@scentregroup/shared/types/content-page'

interface ModuleProps {
  moduleProps: Record<string, unknown>
}

function getAnalyticsMetadataDisplayId(metadata: unknown): string | null {
  if (metadata && has(metadata, 'displayId') && isString(metadata.displayId)) {
    return metadata.displayId
  }
  return null
}

const Module: React.FC<ModuleProps> = ({ moduleProps }) => {
  switch (moduleProps.displayMode) {
    case 'Featured Content':
      return (
        <PromotionPositionContext.Provider
          value={getAnalyticsMetadataDisplayId(moduleProps?.analyticsMetadata)}
        >
          <FeaturedContent color={moduleProps.color} title={moduleProps.title}>
            {(Array.isArray(moduleProps.entries)
              ? moduleProps.entries
              : []
            ).map(entry => (
              <DeckCard {...entry} key={entry.id} />
            ))}
          </FeaturedContent>
        </PromotionPositionContext.Provider>
      )

    case 'Promotion':
      return (
        <Container gutter="spacing-none" sx={{ maxWidth: 'size-max-fixed' }}>
          <Promotion
            {...(moduleProps as unknown as IPromotion & {
              analyticsMetadata: Record<string, unknown>
            })}
          />
        </Container>
      )

    case 'Comparison Item':
      return (
        <Container gutter="spacing-none" sx={{ maxWidth: 'size-max-fixed' }}>
          <Box
            sx={{
              display: 'flex',
              marginX: ['spacing-none', null, 'spacing-md'],
              flexFlow: 'row wrap',
              '& > *': {
                width: [
                  '100%',
                  null,
                  'calc((100% - var(--chakra-space-spacing-lg)) / 2)',
                ],
              },
              '& > * + *': {
                marginTop: ['spacing-lg', null, 'spacing-none'],
                marginLeft: ['spacing-none', null, 'spacing-lg'],
              },
            }}
          >
            {(Array.isArray(moduleProps.entries)
              ? moduleProps.entries
              : []
            ).map(entry => (
              <ComparisonItem {...entry} key={entry.productName} />
            ))}
          </Box>
        </Container>
      )
    default:
      console.log('unsupported display mode:', moduleProps)
      return null
  }
}

export default Module
