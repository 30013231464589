import React from 'react'
import classNames from 'classnames'
import { H2, H4 } from '@hub/heading'
import EventNotification from '../event-notification'
import style from './index.module.scss'

interface ArticleProps {
  superheading?: string
  heading: string
  children?: React.ReactNode
}

const Article: React.FC<React.PropsWithChildren<ArticleProps>> = ({
  superheading,
  heading,
  children,
}: ArticleProps) => (
  <article className={classNames(style.Article, style.evolved)}>
    <div className={style.containFlexChildrenForIE11}>
      {superheading && (
        <H4
          sx={{
            fontSize: 'font-lg',
            textTransform: 'uppercase',
            color: 'textBrandPrimary',
          }}
        >
          {superheading}
        </H4>
      )}
      {superheading && superheading.toUpperCase() === 'EVENT' && (
        <EventNotification className={style.warningNotification} />
      )}
      <H2 fontSize={['font-h2-mobile', 'font-h2-mobile', 'font-h1']}>
        {heading}
      </H2>
      <div className={style.body}>{children}</div>
    </div>
  </article>
)

export default Article
